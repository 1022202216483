import request from 'lib/request'

const accountApi = {
  getUser: () => request.get('/api/account/me'),
  login: ({ loginId, password, remember }) =>
    request.post('/api/ceo/login', { loginId, password, remember }),
  forceLogin: (id) => request.get(`/api/admin/forceLogin/${id}`),
  // 혅재 유저 비밀번호 변경
  password: (payload) => request.post('/api/account/password', payload),
}

export default accountApi
