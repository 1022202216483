import React, { useState } from 'react'
import {
  Divider,
  Button,
  message,
  Modal,
  Space,
  Image,
  Alert,
  Row,
  Col,
} from 'antd'
import api from '../../api'
import moment from 'moment'
import 'moment/locale/ko'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import MoneyLogModal from '../MoneyLogModal'

const apiUrl = process.env.REACT_APP_API_URL

const ReviewDetail = (props) => {
  const { open, review, handleClose, autoHyphen } = props
  const [loading, setLoading] = useState(false)

  const [moneyLogModalOpen, setMoneyLogModalOpen] = useState(false)

  const handleMoneyModalOpen = (review) => {
    setMoneyLogModalOpen(true)
  }

  const handleMoneyModalClose = () => {
    setMoneyLogModalOpen(false)
  }

  if (!review) return null

  const sendGifticon = () => {
    // console.log('send Gifticon')

    Modal.confirm({
      title: '상품권을 발송 하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: '발송하기',
      cancelText: '창닫기',
      onOk: () => {
        setLoading(true)
        api
          .sendGifticon(review.id)
          .then(() => {
            handleClose()
            message.success('발송되었습니다.')
          })

          .catch((error) => message.error(error.message))
          .finally(() => {
            setLoading(false)
          })
      },
    })
  }

  const cancelGifticon = () => {
    // console.log('cancel ')

    Modal.confirm({
      title: '발송된 상품권을 취소 하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: '취소하기',
      cancelText: '창닫기',
      onOk: () => {
        setLoading(true)
        api
          .cancelGifticon(review.id)
          .then(() => {
            handleClose()
            message.success('취소되었습니다.')
          })
          .catch((error) => message.error(error.message))
          .finally(() => {
            setLoading(false)
          })
      },
    })
  }

  const resendGifticon = () => {
    // console.log('resend')

    Modal.confirm({
      title: '상품권을 재발송 하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: '재발송하기',
      cancelText: '창닫기',
      onOk: () => {
        setLoading(true)
        api
          .resendGifticon(review.id)
          .then(() => {
            handleClose()
            message.success('재발송되었습니다.')
          })
          .catch((error) => message.error(error.message))
          .finally(() => {
            setLoading(false)
          })
      },
    })
  }

  const handleDeleteMoneyLog = () => {
    Modal.confirm({
      title: '현급지급을 취소하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: '취소하기',
      cancelText: '창닫기',
      onOk: () => {
        api
          .deleteMoneyLog(review)
          .then(() => {
            message.success('적용하였습니다.')
            handleClose()
          })
          .catch((error) => {
            error.message && message.error(error.message)
          })
          .finally(() => {})
      },
    })
  }

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleClose}
      style={{ top: 0 }}
      footer={false}
    >
      <div
        key={review.id}
        style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
      >
        <Row>
          <Col flex="1 1 100px"> [ {review.storeName} ] </Col>
          <Col>
            <span style={{ color: 'gray', fontSize: 10 }}>
              {review.createdAt}
            </span>{' '}
            <span style={{ fontWeight: 'bold', color: 'brown' }}>
              {moment(review.createdAt).fromNow()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col flex="1 1 100px">
            <div
              style={{
                color: review.isNew === 'Y' ? '#197fd3' : 'brown',
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              {autoHyphen(review.mobile)}
            </div>
          </Col>
          <Col>
            <div
              style={{
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              <div>{review.gifticonName}</div>
            </div>
          </Col>
        </Row>
        <div></div>
        {review.message && (
          <div style={{ marginTop: 10 }}>
            <Alert type="info" message={review.message} />
          </div>
        )}
        <Space style={{ marginTop: 20 }} align="center">
          {(review.done === 'N' || review.done === 'C') && (
            <>
              <Button type="primary" onClick={sendGifticon} disabled={loading}>
                상품권발송
              </Button>
              <Button
                type="primary"
                onClick={handleMoneyModalOpen}
                disabled={loading}
              >
                현금지급 설정
              </Button>
            </>
          )}
          {review.done === 'Y' && review.direct_money < 1 && (
            <>
              <Button onClick={resendGifticon} disabled={loading}>
                재발송
              </Button>
              <Button
                type="primary"
                danger
                onClick={cancelGifticon}
                disabled={loading}
              >
                발송취소
              </Button>
            </>
          )}
          {review.done === 'Y' && review.direct_money > 0 && (
            <>
              <Button
                type="primary"
                danger
                onClick={handleDeleteMoneyLog}
                disabled={loading}
              >
                {review.direct_money}원 현급지급 취소
              </Button>
            </>
          )}

          <Button onClick={handleClose}>닫기</Button>
        </Space>
        <Divider plain style={{ paddingTop: 20, paddingBottom: 20 }}>
          등록 사진
        </Divider>
        {/* <div style={{}}>
          <Image src={apiUrl + review.image} width="100%" />
        </div> */}

        <div>
          {review.images &&
            review.images.map((image) => (
              <Image
                key={image.path}
                src={apiUrl + image.path}
                width="100%"
                style={{ marginBottom: 16 }}
              />
            ))}
        </div>
      </div>
      <MoneyLogModal
        open={moneyLogModalOpen}
        onClose={handleMoneyModalClose}
        addMoneyLog={api.addMoneyLog}
        review={review}
        onSuccess={handleClose}
      />
    </Modal>
  )
}

export default ReviewDetail
