import React from 'react'
import { Button, Modal } from 'antd'

const terms = ` 1.수집 항목
 . 신청자: 휴대폰번호, 리뷰캡쳐사진, 주소, 메모
 . 운영자: 이메일주소, 사무실전화, 회사명
 . 생성 및 기타 수집 정보: 시스템 정보(IP, OS등)

2.수집, 이용목적
 .  신청서비스 제공 및 모바일발송 안내, 서비스이용목적에 대한 통계.분석, 마케팅
 .  서비스 가입, 개통, 변경, 사후관리, 품질개선, 해지, 고지사항 전달.

3.보유 및 이용기간
 . 이용자의 동의 없이 개인정보를 외부에 제공하지 않습니다.
 . 전자적 파일 형태의 경우 삭제후 재생이 불가한방법의로 파기 
 . 위 개인정보의 수집 이용제공과 관련하여 해지후 1 년까지 
  보유 이용합니다. 
   
4.거부권 및 거부시 불이익
 . 위 개인정보의 수집 이용 제공과 관련하여 개인정보 동의를 거부할수 있습니다. 다만, 개인정보는 리뷰왕도토리맨 서비스 이용을 위하여 반드시 필요한 정보이므로 개인정보 수집이용 등의 거부시에는 서비스 이용에 제한이 있을 수 있습니다.`

const TermsModal = (props) => {
  const { modal, handleClose } = props

  return (
    <Modal
      title="정보이용약관"
      open={modal.open}
      afterClose={handleClose}
      onCancel={handleClose}
      onOk={handleClose}
      footer={false}
      style={{ top: 4 }}
    >
      <div style={{ fontSize: 12 }}>
        {terms.split('\n').map((line, index) => {
          return (
            <span key={index}>
              {line}
              <br />
            </span>
          )
        })}
      </div>
      <div style={{ marginTop: 24, textAlign: 'center' }}>
        <Button type="primary" onClick={handleClose}>
          정보이용약관 확인
        </Button>
      </div>
    </Modal>
  )
}

export default TermsModal
