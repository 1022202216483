import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../api'
import ReviewForm from 'modules/customer/components/ReviewForm'
import { useHistory } from 'react-router-dom'

const ReviewEventPage = (props) => {
  let { storeId, gifticonCode } = useParams()

  const [event, setEvent] = useState(null)
  const [eventType, setEventType] = useState('default')
  const history = useHistory()

  useEffect(() => {
    fetchEvent()
  }, [])

  const fetchEvent = () => {
    api
      .fetchEvent(storeId, gifticonCode)
      .then((response) => {
        // console.log(response)
        setEvent(response.event)

        // CU 5,000원권
        if (response.event.gifticonCode === 'G00000576472') {
          setEventType('cu5000')
        }

        // CU 3,000원권
        if (response.event.gifticonCode === 'G00000576471') {
          setEventType('cu3000')
        }
      })
      .catch((error) => {
        // TODO: 리뷰 404 페이지로 이동
        console.log(error)
      })
  }

  if (!event) return ''

  return (
    <div>
      <ReviewForm event={event} history={history} eventType={eventType} />
    </div>
  )
}

export default ReviewEventPage
