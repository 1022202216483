import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Input, Divider, Spin } from 'antd'
import ReviewDetail from '../ReviewDetail'
import moment from 'moment'
import 'moment/locale/ko'
import api from '../../api'
import { LoginOutlined } from '@ant-design/icons'
import UserReivewList from '../UserReivewList'

const VERSION = process.env.REACT_APP_VERSION

const ReviewList = (props) => {
  const { user, handleLogout } = props
  const [loading, setLoading] = useState(false)
  const [reviews, setReviews] = useState([])
  const [pagination, setPagenation] = useState(null)
  const [open, setOpen] = useState(false)
  const [currentReview, setCurrentReview] = useState(null)
  const [mobile, setMobile] = useState(null)
  const [cashes, setCashes] = useState([])
  const [ceoCash, setCeoCash] = useState(0)
  // 유저 리뷰리스트 All(다름내장 포함)
  const [openUserModal, setOpenUserModal] = useState(false)
  const [userMobile, setUserMobile] = useState(null)

  useEffect(() => {
    fetch({})
    fetchCash()
    loadCeoCash()
  }, [])

  const fetch = (meta) => {
    setLoading(true)
    api
      .fetch(meta)
      .then((response) => {
        // console.log(response)
        setReviews(response.data)
        setPagenation({
          page: response.current_page,
          total: response.total,
          lastPage: response.last_page,
          perPage: response.per_page,
        })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSearch = () => {
    fetch({ mobile: mobile })
    fetchCash()

    loadCeoCash()
  }

  const fetchCash = () => {
    setLoading(true)
    api
      .fetchCash()
      .then((response) => {
        setCashes(response.cashes)
        // console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loadCeoCash = () => {
    setLoading(true)
    api
      .cash()
      .then((response) => {
        setCeoCash(response.ceoCash)
        // console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleOpen = (review) => {
    setCurrentReview(review)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrentReview(null)
    setOpen(false)
    fetch({ page: pagination.page })
    fetchCash()
    loadCeoCash()
  }

  const handleNextPage = () => {
    fetch({ page: pagination.page + 1 })
    fetchCash()
    loadCeoCash()
    window.scrollTo(0, 0)
  }

  const handlePreviousPage = () => {
    fetch({ page: pagination.page - 1 })
    fetchCash()
    loadCeoCash()
    window.scrollTo(0, 0)
  }

  const handleChangeMobile = (e) => {
    setMobile(e.target.value.replace(/[^0-9]/g, ''))
  }

  const handleOpenUserModal = (mobile) => {
    setUserMobile(mobile)
    // setOpenUserModal(true)
  }

  const handleCloseUserModal = () => {
    setUserMobile(null)
    // setOpenUserModal(false)
  }

  return (
    <div style={{ margin: 20 }}>
      <UserReivewList
        open={!!userMobile}
        onOk={handleCloseUserModal}
        onCancel={handleCloseUserModal}
        mobile={userMobile}
        handleLogout={handleLogout}
      />
      <ReviewDetail
        open={open}
        review={currentReview}
        handleClose={handleClose}
        autoHyphen={autoHyphen}
      />
      <div style={{ display: 'flex' }}>
        <div style={{ marginBottom: 30 }}>
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>
            리뷰이벤트 참여리스트
          </div>
          <div style={{ color: 'gray' }}>
            실제 참여한 리뷰와 캡쳐사진을 대조확인후 상품권을 발송해주세요.
          </div>
        </div>
        <div style={{ marginLeft: 40 }}>
          <span
            style={{ fontSize: 18, color: 'red', cursor: 'pointer' }}
            onClick={handleLogout}
          >
            <LoginOutlined />
          </span>
        </div>
      </div>
      <div
        style={{
          borderBottom: '2px solid lightgray',
          marginBottom: 10,
          paddingBottom: 5,
        }}
      >
        <Row gutter={10}>
          <Col>
            <div style={{ paddingTop: 3, fontSize: 16 }}>
              <span style={{ color: 'gray' }}>
                잔액:{' '}
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                  {ceoCash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </span>
                원
              </span>
            </div>
          </Col>
          <Col flex="1 1 100px">
            <Input
              name="mobile"
              value={mobile}
              onChange={handleChangeMobile}
              placeholder="폰번호 검색"
              type="number"
            />
          </Col>
          <Col>
            <Button type="primary" onClick={handleSearch}>
              검색
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <Spin spinning={loading}>
          {reviews.map((review) => {
            return (
              <div
                key={review.id}
                style={{
                  padding: '10px 0',
                  marginBottom: 10,
                  borderBottom: '1px solid lightgray',
                }}
              >
                <Row>
                  <Col flex="1 1 200px">
                    <div>[ {review.storeName} ]</div>
                    <div
                      style={{
                        color: review.isNew === 'Y' ? '#197fd3' : 'brown',
                        fontWeight: 'bold',
                        fontSize: 18,
                      }}
                      onClick={() => handleOpenUserModal(review.mobile)}
                    >
                      {autoHyphen(review.mobile)}
                    </div>
                    <div
                      style={{
                        color: 'gray',
                        fontWeight: 'bold',
                      }}
                    >
                      <div>{review.gifticonName}</div>
                    </div>
                    {/* <div>{review.gifticonBrand}</div> */}
                    <div>
                      <span style={{ color: 'gray', marginRight: 16 }}>
                        {review.createdAt}
                      </span>
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: review.done === 'N' ? '#197fd3' : 'brown',
                        }}
                      >
                        {moment(review.createdAt).fromNow()}
                      </span>
                    </div>
                  </Col>
                  <Col style={{ display: 'flex', alignItems: 'end' }}>
                    <div>
                      <Button
                        onClick={() => handleOpen(review)}
                        shape="round"
                        type={
                          review.done === 'N' || review.done === 'C'
                            ? 'primary'
                            : 'default'
                        }
                        // disabled={review.done === 'C'}
                        // danger
                      >
                        {review.done === 'Y' &&
                          review.direct_money < 1 &&
                          '상품권 '}
                        {review.done === 'Y' &&
                          review.direct_money > 0 &&
                          '현금 '}
                        {review.done === 'Y' && '완료'}
                        {/* {review.done === 'C' && '취소'} */}
                        {(review.done === 'N' || review.done === 'C') && '보기'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })}
        </Spin>
        {pagination && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              type="primary"
              disabled={pagination.page - 1 < 1}
              onClick={handlePreviousPage}
            >
              이전
            </Button>
            <Button type="text">{pagination.page}</Button>
            <Button
              type="primary"
              disabled={pagination.lastPage === pagination.page}
              onClick={handleNextPage}
            >
              다음
            </Button>
          </div>
        )}
        <Divider plain style={{ paddingTop: 20, paddingBottom: 20 }}>
          리뷰왕 도토리맨
        </Divider>
        <div style={{ textAlign: 'center', color: 'lightgray' }}>
          버전 {VERSION}
        </div>
      </div>
    </div>
  )
}

export default ReviewList

const autoHyphen = (value) => {
  return value
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
}
