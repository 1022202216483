import request from 'lib/request'
import axios from 'lib/request/axios'

const api = {
  fetchEvent: (storeId, gifticonCode) =>
    request.get(`/api/customer/review/${storeId}/${gifticonCode}`),

  register: (storeId, gifticonCode, payload) =>
    request.post(`/api/customer/review/${storeId}/${gifticonCode}`, payload),
}
export default api
