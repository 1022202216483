import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import api from 'modules/ceo/api'

const UserReviewList = ({ mobile, open, onCancel, handleLogout }) => {
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    fetchUserReviewList(mobile)
  }, [mobile])

  const fetchUserReviewList = (mobile) => {
    if (!mobile) return
    api
      .fetchUserReview(mobile)
      .then((res) => {
        setReviews(res)
      })
      .catch((error) => {
        handleLogout()
      })
  }

  const handleClose = () => {
    onCancel()
    setReviews([])
  }

  return (
    <Modal
      title={mobile && `${autoHyphen(mobile)} 참여리스트`}
      open={open}
      onCancel={onCancel}
      footer={false}
    >
      {reviews.map((rv) => (
        <div
          key={rv.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 5px',
            // borderBottom: '1px solid lightgray',
          }}
        >
          <div>{rv.storeName}</div>
          <div>{rv.createdAt}</div>
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button type="primary" onClick={handleClose}>
          확인
        </Button>
      </div>
    </Modal>
  )
}

export default UserReviewList

const autoHyphen = (value) => {
  return value
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
}
