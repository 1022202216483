import { call, put } from 'redux-saga/effects'
import history from 'lib/history'

function errorHandler(failAction) {
  const error = failAction.payload.error
  // console.log(error)
  if (error.status === 401) {
    history.push('/login')
  }
  // if (error.status === 403) {
  //   history.push('/')
  // }
  // if (error.status === 404) {
  //   history.push('/')
  // }
  // if (error.status === 500) {
  //   history.push('/')
  // }
  // if (failAction.payload.error && failAction.payload.error.message) {
  //   const payload = {
  //     message: failAction.payload.error.message,
  //     severity: 'error',
  //   }
  //   // yield put(clientActions.openToast(payload))
  // }
  // // yield call openToast("error");
  // yield call(tttt)
}

export default function* apiSaga(api, asyncAction, options) {
  yield put(asyncAction.request())
  try {
    const payload = options && options.apiPayload
    const result = yield call(api, payload)
    yield put(asyncAction.success(result))
    return true
  } catch (error) {
    console.log(error)
    const failAction = asyncAction.fail({ error })
    yield call(errorHandler, failAction)
    yield put(failAction)
    return false
  }
}
