import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const ReviewSuccessPage = (props) => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  return (
    <div>
      <div style={{ height: '100vh', backgroundColor: '#758aff' }}>
        <img
          src={
            query?.receipt
              ? '/images/registSuccess5000.jpg'
              : '/images/registSuccess.png'
          }
          alt="리뷰이벤트에 접수되었습니다."
          width="100%"
          style={{ marginTop: 30 }}
        />
      </div>
    </div>
  )
}

export default ReviewSuccessPage
