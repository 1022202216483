import React, { useState } from 'react'
import { Input, Checkbox, message } from 'antd'
import TermsModal from './TermsModal'
import api from 'modules/customer/api'
import PhotoUpload from './PhotoUpload'

const initialModal = {
  open: false,
}

const initialInputs = {
  mobile: '',
  image: '',
  message: '',
  confirm: false,
}

const ReviewForm = (props) => {
  const { event, history, eventType } = props
  const [modal, setModal] = useState(initialModal)
  const [inputs, setInputs] = useState(initialInputs)
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    const patternMobile = /01[016789][0-9]{7,8}/
    if (!inputs.mobile || !patternMobile.test(inputs.mobile)) {
      message.error('휴대폰번호를 확인해주세요.')
      return false
    }
    if (!inputs.image) {
      message.error('리뷰 캡쳐사진을 등록해주세요.')
      return false
    }
    if (!inputs.confirm) {
      message.error('정보이용약관에 동의해주세요.')
      return false
    }

    // 등록 성공페이지 출력이미지가 이벤트 종류에 따라 다름
    // CU 5,000원권만 다르게 출력한다.
    const successUrl =
      eventType === 'cu5000' ? '/review/success?receipt=1' : '/review/success'

    api
      .register(event.storeId, event.gifticonCode, inputs)
      .then((response) => {
        history.push(successUrl)
      })
      .catch((error) => {
        if (error.message) {
          message.error(error.message)
          return false
        } else {
          message.error('지금은 등록할 수 없습니다. 다음에 다시 등록해주세요.')
          return false
        }
      })
  }

  const handleOpen = () => {
    setModal({ ...modal, open: true })
  }

  const handleClose = () => {
    setModal({ ...modal, open: false })
  }

  const handleChangeMobile = (e) => {
    setInputs({
      ...inputs,
      mobile: e.target.value.replace(/[^0-9]/g, ''),
    })
  }

  const handleChangeInput = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    })
  }

  const getEventInfo = () => {
    if (eventType === 'cu5000') {
      return <ReviewInfoReceipt />
    } else if (eventType === 'cu3000') {
      return <ReviewInfo />
    } else {
      return <DefaultInfo />
    }
  }

  // console.log(inputs)
  return (
    <div>
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: 18, marginBottom: 10 }}>
          <div style={{ color: '#1890ff' }}>
            <span style={{ fontWeight: 'bold' }}>{event.storeName}</span>에서
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>{event.gifticonName}</span>{' '}
            받는 방법
          </div>
        </div>
        {getEventInfo()}
      </div>
      <div style={{ padding: '0 20px' }}>
        <div style={{ fontWeight: 'bold', marginBottom: 4 }}>
          상품권 받을 폰번호
        </div>
        <div>
          <Input
            name="mobile"
            value={inputs.mobile}
            type="number"
            onChange={handleChangeMobile}
            placeholder="01012345678"
            maxLength={11}
            style={{ fontWeight: 'bold' }}
          />
        </div>
        {/* 영수증이베트일 경우(cu5000)은 타이틀을 바꾼다. */}
        {eventType === 'cu5000' ? (
          <div style={{ marginTop: 16, fontWeight: 'bold' }}>
            <span style={{ fontWeight: 'bold' }}>영수증 사진등록</span>
          </div>
        ) : (
          <div style={{ marginTop: 16, fontWeight: 'bold' }}>
            <span style={{ fontWeight: 'bold' }}>리뷰 캡쳐사진 등록</span>
          </div>
        )}
        <PhotoUpload
          event={event}
          setLoading={setLoading}
          inputs={inputs}
          setInputs={setInputs}
          loading={loading}
        />
        <div style={{ marginTop: 16, fontWeight: 'bold' }}>남기실 말씀</div>
        <div>
          <Input name="message" onChange={handleChangeInput} maxLength={200} />
        </div>
      </div>

      <div style={{ marginTop: 16 }}>
        <div style={{ padding: '0 20px 10px' }}>
          <Checkbox
            checked={inputs.confirm}
            onChange={() => setInputs({ ...inputs, confirm: !inputs.confirm })}
          >
            정보이용약관에 동의합니다.
            <span
              style={{ cursor: 'pointer', color: 'blue', marginLeft: 8 }}
              onClick={handleOpen}
            >
              [약관보기]
            </span>
          </Checkbox>
        </div>
        <div
          style={{
            cursor: 'pointer',
            padding: 16,
            textAlign: 'center',
            backgroundColor: '#ffeb33',
            border: '1px solid lightgray',
            borderRadius: 10,
            margin: '10px 20px 0',
          }}
          onClick={handleSubmit}
        >
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>신청하기</span>
        </div>
      </div>
      <TermsModal handleClose={handleClose} modal={modal} />
    </div>
  )
}

export default ReviewForm

// 기본 이벤트
const DefaultInfo = () => (
  <div>
    <div
      style={{
        fontWeight: 'bold',
        // color: 'gray',
        borderLeft: '5px solid #1890ff',
        paddingLeft: 10,
        marginLeft: 10,
      }}
    >
      <ol style={{ paddingLeft: 16 }}>
        <li>배달앱에 별다섯개+사진리뷰 or 네이버플레이스 영수증리뷰를 쓴다.</li>
        <li>닉네임이 보이게 캡쳐하여 첨부한다.</li>
        <li>문자메시지로 상품권을 받는다.</li>
        <li>카카오페이로 원하실 경우 남기실 말씀에 요청남겨주세요.</li>
      </ol>
    </div>
  </div>
)

// CU 3,000원 상품권 이벤트
const ReviewInfo = () => (
  <div>
    <div
      style={{
        fontWeight: 'bold',
        // color: 'gray',
        borderLeft: '5px solid #1890ff',
        paddingLeft: 10,
        marginLeft: 10,
      }}
    >
      <ol style={{ paddingLeft: 16 }}>
        <li>배민,요기요,쿠팡(택1) 2번 주문후 리뷰를 2번 남긴다.</li>
        <li>리뷰사진1, 리뷰사진2를 캡쳐한다.</li>
        <li>아래에 캡쳐사진2장을 첨부한다.</li>
        <li>상품권은 문자로 현금은 카카오페이로 받는다.</li>
      </ol>
    </div>

    <div style={{ marginLeft: 20, marginTop: 8 }}>
      <div style={{ color: 'darkred' }}>※ 주의사항</div>
      <ul style={{ paddingLeft: 16, listStyleType: 'square' }}>
        <li>현금요청시 "남기실말씀"에 "현금"으로 입력해주세요.</li>
        <li>유효기간을 꼭 확인해주세요.</li>
      </ul>
      <div></div>
    </div>
  </div>
)

// CU 5,000원 상품권 이벤트
const ReviewInfoReceipt = () => (
  <div>
    <div
      style={{
        fontWeight: 'bold',
        // color: 'gray',
        borderLeft: '5px solid #1890ff',
        paddingLeft: 10,
        marginLeft: 10,
      }}
    >
      <ol style={{ paddingLeft: 16 }}>
        <li>서로 다른매장 홀&배달주문 영수증[2만원이상] 2매를 모은다.</li>
        <li>주문 영수증 2매 또는 3매(동일매장)를 사진촬영한다</li>
        <li>마지막 주문매장 QR에 사진첨부한다..</li>
        <li>상품권은 문자로 현금은 카카오페이로 받는다.</li>
      </ol>
    </div>
    <div style={{ marginLeft: 20, marginTop: 8 }}>
      <div style={{ color: 'darkred' }}>※ 주의사항</div>
      <div>■ 이벤트 유효기간 꼭 확인해주세요</div>
      <div>■ 영수증은 1일 1회에 한함/중복사용불가</div>
      <div>■ 주문금액 2만원이상[배달료제외]</div>
      <div>■ 동일 매장 이용시(영수증3매)신청가능</div>
      <div>■ 일부매장[전화주문 영수증만 가능]</div>
      <div>■ 주소지없는 영수증은 상품권 신청불가</div>
      <div></div>
    </div>
  </div>
)
