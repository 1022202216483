import React, { useState } from 'react'
import { Upload, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
const apiUrl = process.env.REACT_APP_API_URL

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const PhotoUpload = ({ event, setLoading, inputs, setInputs, loading }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
  }

  const handleCancel = () => setPreviewOpen(false)

  const uploadProps = {
    name: 'photo',
    multiple: true,
    action:
      event &&
      `${apiUrl}/api/customer/review/${event.storeId}/${event.gifticonCode}/photo`,
    accept: 'image/x-png,image/gif,image/jpeg',
    listType: 'picture-card',
    fileList: fileList,
    onPreview: handlePreview,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setLoading(true)
      }

      // 파일을 업로드하고나서 서버에서 응답을 받거나 파일을 삭제하면
      if (info.file.status === 'done' || info.file.status === 'removed') {
        const images = info.fileList
          .filter(
            (file) => file.status === 'done' && file.response?.photo?.image
          )
          .map((file) => file.response.photo.image)
        setInputs({
          ...inputs,
          image: info.file.response.photo.image,
          images: images,
        })
        setLoading(false)
      } else if (info.file.status === 'error') {
        // 파일 업로드에 실패하면
        message.error(`사진 등록에 실패했습니다..`)
        setLoading(false)
        setTimeout(() => {
          // 서버에서 응답을 받지 못하면 fileList에서 삭제
          setFileList(info.fileList.filter((file) => file.status !== 'error'))
        }, 500)
      }
      setFileList(info.fileList)
    },
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        사진 등록
      </div>
    </div>
  )

  return (
    <div>
      <Upload {...uploadProps} showUploadList={true}>
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  )
}

export default PhotoUpload
