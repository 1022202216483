import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { AppLoader } from 'modules/core/app'
// import { logout } from 'modules/core/account/store'
// import history from 'lib/history'

// core modules routes
import { LoginPage } from 'modules/core/account'
// import LoginPage from 'modules/ceo/containers/LoginPage'

// ceo
import ReviewListPage from 'modules/ceo/containers/ReviewListPage'

// customer
import ReviewEventPage from 'modules/customer/containers/ReviewEventPage'
import ReviewSuccessPage from 'modules/customer/containers/ReviewSuccessPage'

const Routes = (props) => {
  const loaded = useSelector((store) => store.app.loaded)

  if (!loaded) return <AppLoader />

  return (
    <>
      <Switch>
        {/* <Route path="/" exact render={() => <Redirect to="/stores" />} /> */}
        <Route path="/ceo" exact component={LoginPage} />
        <Route path="/ceo/reviews" exact component={ReviewListPage} />
        <Route path="/review/success" exact component={ReviewSuccessPage} />
        <Route
          path="/review/:storeId/:gifticonCode"
          exact
          component={ReviewEventPage}
        />
        <Route path="*" render={() => <Redirect to="/ceo" />} />
      </Switch>
    </>
  )
}

export default Routes
