import React from 'react'
import { logout } from 'modules/core/account/store'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ReviewList from 'modules/ceo/components/ReviewList'

import history from 'lib/history'

const ReviewListPage = (props) => {
  const user = useSelector((store) => store.account.user)
  const dispatch = useDispatch()
  // console.log(user)

  // 무한루프에 빠질 수 있음.
  if (!user || user.role !== 'ceo' || !user.id) return <Redirect to="/ceo" />

  const handleLogout = () => {
    dispatch(logout())
    history.push('/ceo')
  }

  return (
    <div>
      <ReviewList user={user} handleLogout={handleLogout} />
    </div>
  )
}

export default ReviewListPage
