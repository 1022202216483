import request from 'lib/request'
// import axios from 'lib/request/axios'
// const getRequest = (url, data) =>
//   axios({ method: 'get', url, params: data })
//     .then(dataHandler)
//     .catch(errorHandler)
// const postRequest = (url, data) =>
//   axios({ method: 'post', url, data }).then(dataHandler).catch(errorHandler)

const api = {
  fetch: (meta) => request.get(`/api/ceo/reviews`, meta),
  // fetch: () => axios({ method: 'get', url: `/api/ceo/reviews` }),
  fetchCash: () => request.get(`/api/ceo/cashes`),
  cash: () => request.get(`/api/ceo/cash`),
  sendGifticon: (reviewId) => request.post(`/api/ceo/reviews/${reviewId}/send`),
  resendGifticon: (reviewId) =>
    request.post(`/api/ceo/reviews/${reviewId}/resend`),
  cancelGifticon: (reviewId) =>
    request.post(`/api/ceo/reviews/${reviewId}/cancel`),
  fetchUserReview: (mobile) => request.get(`/api/ceo/reviews/mobile/${mobile}`),

  addMoneyLog: (review, direct_money) =>
    request.post(`/api/ceo/reviews/${review.id}/money_log/add`, {
      direct_money,
    }),
  deleteMoneyLog: (review) =>
    request.post(`/api/ceo/reviews/${review.id}/money_log/delete`),
}

export default api
